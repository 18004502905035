// url's de producción, desarrollo, seguridad
export const REACT_APP_API_URL_SECURITY = process.env.REACT_APP_API_URL_SECURITY;
export const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
export const REACT_APP_API_URL_DEV = process.env.REACT_APP_API_URL_DEV;
export const REACT_APP_API_URL_PROD = process.env.REACT_APP_API_URL_PROD
export const REACT_APP_API_URL_DOMINIOS = process.env.REACT_APP_API_URL_DOMINIOS
// token de middleware
export const REACT_APP_BASE_csrfmiddlewaretoken = process.env.REACT_APP_BASE_csrfmiddlewaretoken
// tokens de seguridad
export const REACT_APP_Rosen_API_Key = process.env.REACT_APP_Rosen_API_Key
export const REACT_APP_Rosen_API_Secret = process.env.REACT_APP_Rosen_API_Secret
export const REACT_APP_ROSENPROD_KEY = process.env.REACT_APP_ROSENPROD_KEY
// tokens de opciones del menú
export const REACT_APP_DASHBOARD = process.env.REACT_APP_DASHBOARD
export const REACT_APP_ETIQUETA = process.env.REACT_APP_ETIQUETA
export const REACT_APP_GESTIONINVENTARIO = process.env.REACT_APP_GESTIONINVENTARIO
export const REACT_APP_TRATAMIENTOETIQUETAS = process.env.REACT_APP_TRATAMIENTOETIQUETAS
export const REACT_APP_DOCUMENTOS = process.env.REACT_APP_DOCUMENTOS
export const REACT_APP_AVANCEINVENTARIO = process.env.REACT_APP_AVANCEINVENTARIO
export const REACT_APP_REPORTEINVENTARIO = process.env.REACT_APP_REPORTEINVENTARIO
export const REACT_APP_INFORMESALDOS = process.env.REACT_APP_INFORMESALDOS
export const REACT_APP_DESCARGAMASIVA = process.env.REACT_APP_DESCARGAMASIVA
export const REACT_APP_APERTURARECONTEO = process.env.REACT_APP_APERTURARECONTEO
export const REACT_APP_AVANCEYCIERRERECONTEO = process.env.REACT_APP_AVANCEYCIERRERECONTEO
export const REACT_APP_PERIODOMUESTRA = process.env.REACT_APP_PERIODOMUESTRA
export const REACT_APP_AVANCEYCIERREMUESTRAS = process.env.REACT_APP_AVANCEYCIERREMUESTRAS
export const REACT_APP_SALDOSMUESTRA = process.env.REACT_APP_SALDOSMUESTRA
export const REACT_APP_CLASIFICACIONDIFERENCIAS = process.env.REACT_APP_CLASIFICACIONDIFERENCIAS
export const REACT_APP_CLASIFICACIONMUESTRAS = process.env.REACT_APP_CLASIFICACIONMUESTRAS
export const REACT_APP_KARDEXINICIAL = process.env.REACT_APP_KARDEXINICIAL
export const REACT_APP_KARDEXFINAL = process.env.REACT_APP_KARDEXFINAL
export const REACT_APP_ROLLFORWRAD = process.env.REACT_APP_ROLLFORWRAD
export const REACT_APP_DETALLEAJUSTES = process.env.REACT_APP_DETALLEAJUSTES
export const REACT_APP_GESTIONMUESTRAS = process.env.REACT_APP_GESTIONMUESTRAS

// key de nueva appi de seguridad
export const REACT_APP_X_API_KEY = process.env.REACT_APP_X_API_KEY
// tokens permisos eliminar y subir documentos
export const REACT_APP_INV_ELIMINAR_ARCHVOS = process.env.REACT_APP_INV_ELIMINAR_ARCHVOS
export const REACT_APP_INV_SUBIR_ARCHIVOS = process.env.REACT_APP_INV_SUBIR_ARCHIVOS
//Colores predeterminados para clasificación de Documentos
export const REACT_APP_COLOR_DOCUMENTOS = process.env.REACT_APP_COLOR_DOCUMENTOS
export const REACT_APP_COLOR_CLASIFICACION= process.env.REACT_APP_COLOR_CLASIFICACION
