import { useEffect, useState } from "react";
import { Accordion, Button, Container } from "react-bootstrap";
import { FaAngleDoubleUp, FaCalendarAlt, FaCheckCircle, FaDownload, FaRegTrashAlt, FaTruck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import SelectAlmacenes from "../../../components/Almacenes";
import SelectCentro from "../../../components/Centros";
import LocalMsgModalSpinner from "../../../components/LocalMsgModalSpinner";
import * as env from '../../../env';
import { httpApiGetText, httpApiPostText } from "../../../lib";
import DataTable from "react-data-table-component";
import { ConsultaPermisos, exportToExcel } from "../../../util";
import MsgDialog from "../../../components/MsgDialog";
import BarraMenuHz from "../../../components/BarraMenoHz";
import { SetEntornoEmp } from "../../../redux/store/Actions";
import BajarArchivoNube from "./ModalDescargaDocs";

const GestionMuestras = () =>{

    let emp: any = useSelector((state: any) => state.emp);  
    const [hbltBtnAbrir, setHbltBtnAbrir] = useState(true); 
    let [formData, setFormData] = useState({ Pais: "CL", Anno: "", Mes: "",  Centro: "", Almacen: "", Muestra_ref: 0});
    const [sHCarga, setSHCarga] = useState(false);      
    let [records, setRecords] = useState(new Array<any>());  
    const [cpRecords, setCpRecords] = useState(new Array<any>());    
    let [arrData, setArrData] = useState(new Array<any>());   
    const [filtrobusqueda, setFiltroBusqueda] = useState(new Array<any>());     
    const [showMsgApiResponse, setShowMsgApiResponse] = useState(false); 
    const [MsgApiResponse, setMsgApiResponse] = useState("");   
    const [showAutoriza, setShowAutoriza] = useState(false);         
    const [showAjuste, setShowAjuste] = useState(false);      
    const [showElimina, setShowElimina] = useState(false);              
    const [showSubirNube, setShowSubirNube] = useState(false); 
    const [muestra, setMuestra] = useState(0); 
    const [permisoSupervisor, setPermisoSupervisor] = useState(0);     
    const [permisoAjuste, setPermisoAjuste] = useState(0);         
    const [permisoElimina, setPermisoElimina] = useState(0);                 
    const dispatch = useDispatch();               
      
    let columns = [
        { name: "Centro", selector: (row:any) => row.Centro, sortable: true,  width:"90px"},
        { name: "Almacen", selector: (row:any) => row.Almacen, sortable: true,  width:"100px"},
        { name: "Estado", selector: (row:any) => row.Estado_Muestra, sortable: true,  width:"90px"},
        { name: "Muestra", selector: (row:any) => row.Muestra_ref, sortable: true,  width:"120px"},        
        { name: "Fecha Apertura", selector: (row:any) => row.Publicado_fecha, sortable: true, width:"160px", wrap:true},
        { name: "Usuario Apertura", selector: (row:any) => row.Usuario_apertura, sortable: true,   width:"150px"},
        { name: "Fecha Publicación", selector: (row:any) => row.Publicado_fecha, sortable: true, width:"160px"},            
        { name: "Usuario Publicación", selector: (row:any) => row.Publicado_usuario, sortable: true, width:"160px"},
        { name: "Diferencia", selector: (row:any) => row.Diferencia, sortable: true, width:"150px", right:true},
        { name: "Acciones", selector: (row:any) => row.Acciones, width:"200px"},                           
    ];
    
    const pagOptions = {
        rowsPerPageText: "Filas por páginas",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos"
    };
    
    const ValidaBtnAbrir = () => {     
        if ((formData.Anno !== "") &&
            (formData.Almacen !== "") && 
            (formData.Centro !== "")){     
                setHbltBtnAbrir(false); 
        }else{
            setHbltBtnAbrir(true); 
        }
    }

    const hndlrPeriodo = async (evnt: any) =>{
        const f: Date = evnt.target.value; 
        const yy = f.toString().split('-')[0];
        const mm = f.toString().split('-')[1];

        emp = {...emp, periodoDefault: `${yy}-${mm}`};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));   
                
        formData = {...formData, Anno: yy, Mes: mm};
        setFormData(formData);
        ValidaBtnAbrir();                    
    }

    const hndlrOpcionCentro = (evnt: any) =>{
        formData = {...formData, [evnt.target.id]: evnt.target.value.split("-")[0].trim()};
        setFormData(formData);  
        
        emp = {...emp, centroDefault: evnt.target.value};
        dispatch(SetEntornoEmp({...emp})); 

        sessionStorage.setItem("entorno", JSON.stringify(emp));     

        ValidaBtnAbrir();   
         
    }

    const hndlrOpcionAlmacen = (evnt: any) =>{
        formData = {...formData, [evnt.target.id]: evnt.target.value.split("-")[0].trim()};
        setFormData(formData);     
        
        emp = {...emp, almacenDefault: evnt.target.value};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));  

        ValidaBtnAbrir();                               
    }   

    const AutorizarSupervisor = async () => {

        setShowAutoriza(false);

        const data = {
            Pais: formData.Pais,
            Anno: formData.Anno,
            Mes: formData.Mes,
            Centro: formData.Centro,
            Almacen: formData.Almacen,
            Muestra_ref: muestra,
            Estado_Clasificacion:"2",
            Usu_Autoriza: emp.user
        };
     
        const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestra/CambiaEstadoAutorizacion`
        const response = await httpApiPostText(recurso,"POST", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, data);
        console.log(JSON.parse(response.message));

        if (response.code >= 400){
            // mensaje de error aquí...
            setMsgApiResponse(JSON.parse(response.message).Message);
            setShowMsgApiResponse(true);
        }else{

            btnBuscar();

            setMsgApiResponse("Registro Actualizado Exitosamente");
            setShowMsgApiResponse(false);
        }
    }  

    const AutorizarAjuste = async () => {

        setShowAjuste(false);

        const data = {
            Pais: formData.Pais,
            Anno: formData.Anno,
            Mes: formData.Mes,
            Centro: formData.Centro,
            Almacen: formData.Almacen,
            Muestra_ref: muestra,
            Estado_Clasificacion:"3",
            Usu_Autoriza: emp.user
        };
        const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestra/CambiaEstadoAutorizacion`
        const response = await httpApiPostText(recurso,"POST", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, data);
        if (response.code >= 400){
            // mensaje de error aquí...
            setMsgApiResponse(JSON.parse(response.message).Message);
            setShowMsgApiResponse(true);
        }else{

            btnBuscar();

            setMsgApiResponse("Registro Actualizado Exitosamente");
            setShowMsgApiResponse(false);
        }
    }     
    
    const EliminaMuestra = async () => {
        
        setShowElimina(false);

        const data = {
            Pais: formData.Pais,
            Anno: formData.Anno,
            Mes: formData.Mes,
            Centro: formData.Centro,
            Almacen: formData.Almacen,
            Muestra_ref: muestra,
            Estado_Clasificacion:"4",
            Usu_Autoriza: emp.user
        };
        const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestra/CambiaEstadoAutorizacion`
        const response = await httpApiPostText(recurso,"POST", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, data);

        if (response.code >= 400){
            // mensaje de error aquí...
            setMsgApiResponse(JSON.parse(response.message).Message);
            setShowMsgApiResponse(true);
        }else{
            btnBuscar();

            setMsgApiResponse("Registro Actualizado Exitosamente");
            setShowMsgApiResponse(false);
        }
    }    
    
    const btnBuscar = async () =>{

        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestraList?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}&Almacen=${formData.Almacen}`;     
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });

        const Dta = await JSON.parse(response.message); 

        arrData = [...Dta];
        setArrData([...Dta]);
        setSHCarga(false);  
        if (response.code >= 400){
            console.log("Error de respuesta: ", response.message);
        }else{

            const auxDta:any = [];
            Dta.map((obj: any, index: number)=>{
                const newObj = {
                    ...obj, 
                    Acciones: <div  className=' d-flex gap-1' >                    
                                    {
                                        (obj.Cantidad > 0) 
                                                ? <div  className=' d-flex gap-1' >
                                                    <a href="#!" id={`${index}`} onClick={()=>{
                                                            setFormData({...formData, Muestra_ref: obj.Muestra_ref});
                                                            setShowSubirNube(true);
                                                        }}>
                                                        <FaDownload title="Perdida" color={'white'}  className='bg-secondary rounded-circle p-1 h4'/>  
                                                    </a> 
                                                  </div>    
                                                : null
                                    }
                                    {
                                        (((obj.Estado_Clasificacion === "0") || (obj.Estado_Clasificacion === "1")) && (emp.permisoSupervisor > 0))  
                                            ? 
                                                <div  className=' d-flex gap-1' >
                                                    <a href="#!" id={`${index}`} onClick={()=>{
                                                            setMuestra(obj.Muestra_ref);
                                                            setShowAutoriza(true);
                                                        }}>
                                                            <FaCheckCircle title="Autorizar Supervisor" color={'white'}  className='bg-success rounded-circle p-1 h4'/>                                                              
                                                    </a> 
                                                </div>
                                            : null
                                    }
                                    {
                                        (emp.permisoAjuste > 0) && (obj.Estado_Clasificacion  !== "3")
                                                ? <div  className=' d-flex gap-1' >
                                                    <a href="#!" id={`${index}`} onClick={()=>{
                                                            setMuestra(obj.Muestra_ref);
                                                            setShowAjuste(true);
                                                        }}>
                                                        <FaAngleDoubleUp title="Autorizar Ajuste" color={'white'}  className='bg-warning rounded-circle p-1 h4'/>                                                              
                                                    </a> 
                                                  </div>    
                                                : null
                                    }                                                                                          
                                    {
                                        ((emp.permisoElimina > 0) && (obj.Estado_Muestra === "Abierta"))
                                                ? <div  className=' d-flex gap-1' >
                                                    <a href="#!" id={`${index}`} onClick={()=>{
                                                            setMuestra(obj.Muestra_ref);
                                                            setShowElimina(true);
                                                        }}>
                                                        <FaRegTrashAlt title="Elimina" color={'white'}  className='bg-danger rounded-circle p-1 h4'/>                                                              
                                                    </a> 
                                                  </div>    
                                                : null
                                    }                                    
                                </div>                                                
                };
                auxDta.push(newObj);
            });
            setRecords([...auxDta]);
        }
    }

    useEffect(()=>{

        const preFiltros = () =>{
            if ((emp.periodoDefault !== "")){
                formData = { ...formData, Anno: emp.periodoDefault.split("-")[0],  Mes: emp.periodoDefault.split("-")[1]};
                setFormData(formData);                                         
            }  
            if ((emp.centroDefault !== "")){
                formData = { ...formData, Centro: emp.centroDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }    
            if ((emp.almacenDefault !== "")){
                formData = { ...formData, Almacen: emp.almacenDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }  

            ValidaBtnAbrir(); 
        }

        preFiltros();

        console.log("emp: ",emp);

    }, []);     
  
    return(
        <Container fluid>
            <BarraMenuHz/>  
            <div >
                <div className="text-primary ms-3 mt-3">
                        Home / Muestras / Gestión de Muestras
                </div>
                <div className="h4 mt-4 mb-4 ms-3">GESTION DE MUESTRAS</div>
            </div> 
            <Container fluid>  

                <Accordion  className="mb-4" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">PARÁMETROS GESTIÓN</div></Accordion.Header>
                        <Accordion.Body>
                            <label className="h6 mb-3">Recuerda completar todos los campos con *</label> 
                            <div className="align-middle">
                                <FaCalendarAlt className="h5" /> 
                                <label className="ms-2 h4 ">Periodo</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" />

                            <div  className="d-flex flex-column col-6">
                                <label className="m-2 ">Mes/Año</label>
                                <input type="month" id="periodo" size={8}  className="ms-2"
                                    value={`${formData.Anno}-${formData.Mes}`}
                                    onChange={hndlrPeriodo} 
                                    min="1990-01" max="9999-12"
                                />
                            </div>
                            <div className="align-middle mt-4">
                                <FaTruck className="h5" /> 
                                <label className="ms-2 h4 ">Centro y Almacen</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" /> 

                            <div className="row d-flex flex-row mb-3">
                                <Container fluid className="mb-3 col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Centro de distribución</label>    
                                    <div><SelectCentro id="Centro" OnSeleccion={hndlrOpcionCentro}  default={emp.centroDefault} /></div>
                                </Container>
                                <Container fluid className="mb-3  col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Almacen </label>    
                                    <div ><SelectAlmacenes  id="Almacen" centro={formData.Centro}  OnSeleccion={hndlrOpcionAlmacen}   default={emp.almacenDefault} /></div>
                                </Container>
                            </div>

                            <div className=" col-md-12 col-lg-12 text-end">
                                <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" btn border border-0" 
                                    disabled={hbltBtnAbrir} onClick={() => btnBuscar()}
                                >
                                    Buscar
                                </Button>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>  

                <Accordion className="mt-1" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">MUESTRAS</div></Accordion.Header>
                        <Accordion.Body>
                           
                            <div className="row d-flex flex-row align-middle mt-2 mb-2">
                                <div  className="col-md-12 col-lg-6  d-flex align-items-center" >
                                    <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" border-0"
                                        disabled={(records.length <= 0)}
                                        onClick={()=> {exportToExcel("TablaMuestra", records)}}
                                    >
                                        Exportar tabla a Excel
                                    </Button>
                                </div>                       
                            </div> 
                            <div className="">
                                <DataTable 
                                    columns={columns}
                                    data={records}
                                    selectableRowsHighlight
                                    fixedHeader
                                    pagination
                                    highlightOnHover
                                    fixedHeaderScrollHeight="600px"
                                    paginationComponentOptions={pagOptions}
                                />                                
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion> 

            </Container>
            {showSubirNube && <BajarArchivoNube
                Data={arrData}
                Show={showSubirNube}
                Title="Descargar archivos"
                BtnNokName="Cerrar"
                HanlerdClickNok={()=>{setShowSubirNube(false)}}
                HandlerPreselected={[]}     
                data={formData}           
            />}

            {showMsgApiResponse && <MsgDialog 
                Title="Gestión de Muestras"
                Icon="!"
                Show={showMsgApiResponse}
                BtnNokName=""
                BtnOkName="Cerrar"
                Message={MsgApiResponse}
                HandlerClickOk={()=>setShowMsgApiResponse(false)}
                HanlerdClickNok={null}
            />} 

             {showAutoriza && <MsgDialog 
                Title="Gestión de Muestras"
                Icon="!"
                Show={showAutoriza}
                BtnNokName="No, cancelar"
                BtnOkName="Si, continuar"
                Message="¿Desea continuar con el proceso de autorización por el supervisor?"
                HandlerClickOk={AutorizarSupervisor}
                HanlerdClickNok={()=>setShowAutoriza(false)}
            /> }     

            {showAjuste && <MsgDialog 
                Title="Gestión de Muestras"
                Icon="!"
                Show={showAjuste}
                BtnNokName="No, cancelar"
                BtnOkName="Si, continuar"
                Message="¿Desea continuar con el proceso de autorización de ajuste?"
                HandlerClickOk={AutorizarAjuste}
                HanlerdClickNok={()=>setShowAjuste(false)}
            /> }  

            {showElimina && <MsgDialog 
                Title="Gestión de Muestras"
                Icon="!"
                Show={showElimina}
                BtnNokName="No, cancelar"
                BtnOkName="Si, continuar"
                Message="¿Desea continuar con el proceso de eliminación"
                HandlerClickOk={EliminaMuestra}
                HanlerdClickNok={()=>setShowElimina(false)}
            /> }                     

            {sHCarga && <LocalMsgModalSpinner 
                Show={sHCarga}
                text="Un momento por favor, procesando..."
                color="#FF7588"
            />}             
        </Container>    
    );
}

export default GestionMuestras;