import { useEffect, useState } from "react";
import { Accordion, Button, Container, Form } from "react-bootstrap";
import { FaBars, FaCalendarAlt, FaTruck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import SelectAlmacenes from "../../../components/Almacenes";
import SelectCentro from "../../../components/Centros";
import LocalMsgModalSpinner from "../../../components/LocalMsgModalSpinner";
import * as env from '../../../env';
import { httpApiGetText, httpApiPostText } from "../../../lib";
import DataTable from "react-data-table-component";
import { exportToExcel } from "../../../util";
import MsgDialog from "../../../components/MsgDialog";
import BarraMenuHz from "../../../components/BarraMenoHz";
import { SetEntornoEmp } from "../../../redux/store/Actions";
import MsgDialogDiferencia from "./MsgDialogDiferencia";
import MsgDialogMasiva from "./MsgDialogMasiva";
import MsgDialog3 from "../../../components/MsgDialog3";
import SubirArchivoNube from "./SubirArchivoNube";
  
const ClasificacionMuestras = () =>{

    let emp: any = useSelector((state: any) => state.emp);  
    const [hbltBtnAbrir, setHbltBtnAbrir] = useState(true); 
    const [showCuadroDialogo, setShowCuadroDialogo] = useState(false); 
    const [showCuadroDialogoClasificar, setShowCuadroDialogoClasificar] = useState(false);         
    let [formData, setFormData] = useState({ Pais: "CL", Anno: emp.periodoDefault.substring(0, 4), Mes: emp.periodoDefault.substring(5, 7),  Centro: emp.centroDefault, Almacen: emp.almacenDefault, Muestra: ""});
    const [sHCarga, setSHCarga] = useState(false);  
    const [cpRecords, setCpRecords] = useState(new Array<any>());  
    let [records, setRecords] = useState(new Array<any>());              
    let [formClasificacion, setFormClasificacion] = useState(""); 
    const [showMsgApiResponse, setShowMsgApiResponse] = useState(false); 
    const [showSubirNube, setShowSubirNube] = useState(false);     
    const [MsgApiResponse, setMsgApiResponse] = useState(""); 
    const [showModalDiferencia, setShowModalDiferencia] = useState(false);        
    const [showModalMasiva, setShowModalMasiva] = useState(false);    
    const [showmsgError, setShowmsgError] = useState(false);        
    const [showMsgApiError, setShowMsgApiError] = useState(false); 
    const [MsgApiError, setMsgApiError] = useState("");  
    let [opcionSubMuestras, setOpcionSubMuestras] = useState([""]); 
    let [perc, setPerc] = useState(0);   
    const [muestraPorc, setMuestraPorc] = useState(false);          
    let [rowsPreselected, setRowsPreselected] = useState(new Array<any>());   
    const [clrRows, setCLRows] = useState(false);  
    const dispatch = useDispatch();       
    let [row, setRow] = useState({});       
    let [indice, setIndice] = useState(0); 
         
    let columns = [
        { name: "Código", selector: (row:any) => row.Codigo, sortable: true, width:"90px"},
        { name: "E", selector: (row:any) => row.E, sortable: true, width:"80px"},
        { name: "Descripción", selector: (row:any) => row.Descripcion, sortable: true, width:"350px", wrap:true},
        { name: "Unidad", selector: (row:any) => row.Un, sortable: true, width:"90px"},
        { name: "Costo", selector: (row:any) => row.Costo,  format: (row:any) => row.Costo.toLocaleString(), right:true, sortable: true, width:"140px"},            
        { name: "Bloqueado", selector: (row:any) => row.Bloqueado, sortable: true, right:true, width:"120px"},
        { name: "Diferencia", selector: (row:any) => row.Diferencia,  format: (row:any) => row.Diferencia.toLocaleString(),  sortable: true, width:"120px", right:true},
        { name: "Dif. Valorizada", selector: (row:any) => row.Diferencia_Valorizada, format: (row:any) => row.Diferencia_Valorizada.toLocaleString(), right:true, sortable: true, width:"150px"},           
        { name: "Saldo SAP", selector: (row:any) => row.Saldo_SAP, format: (row:any) => row.Saldo_SAP.toLocaleString(), sortable: true, width:"120px", right:true},  
        { name: "Stock Bodega", selector: (row:any) => row.Total_Stock_Bodega, format: (row:any) => row.Total_Stock_Bodega.toLocaleString(), sortable: true, width:"130px", right:true},    
        { name: "Stock SAP", selector: (row:any) => row.Total_Stock_SAP, format: (row:any) => row.Total_Stock_SAP.toLocaleString(), sortable: true, width:"120px", right:true},                           
        { name: "Clasificado", selector: (row:any) => row.Clasificado, format: (row:any) => row.Clasificado.toLocaleString(), sortable: true, width:"130px", right:true},    
        { name: "Clasificar", selector: (row:any) => row.Clasificar, format: (row:any) => row.Clasificar.toLocaleString(), sortable: true, width:"120px", right:true},                           
        { name: "Acciones", selector: (row:any) => row.Acciones, grow:2},    
    ];

    const pagOptions = {
        rowsPerPageText: "Filas por páginas",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos"
    };  

    const ValidaBtnAbrir = () => {     
        if ((formData.Anno !== "") &&
            (formData.Almacen !== "") && 
            (formData.Centro !== "") && (formData.Muestra !== "")){              
                setHbltBtnAbrir(false); 
        }else{
            setHbltBtnAbrir(true); 
        }
    }

    const hndlrPeriodo = async (evnt: any) =>{
        const f: Date = evnt.target.value; 
        const yy = f.toString().split('-')[0];
        const mm = f.toString().split('-')[1];

        emp = {...emp, periodoDefault: `${yy}-${mm}`};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));   
                
        formData = {...formData, Anno: yy, Mes: mm};
        setFormData(formData);
        LLenarSelectorMuestra();
                    
    }

    const hndlrOpcionCentro = (evnt: any) =>{
        formData = {...formData, [evnt.target.id]: evnt.target.value.split("-")[0].trim(), Almacen: emp.almacenDefault.split("-")[0].trim()};
        setFormData(formData);  
        
        emp = {...emp, centroDefault: evnt.target.value};
        dispatch(SetEntornoEmp({...emp})); 

        sessionStorage.setItem("entorno", JSON.stringify(emp));     

        LLenarSelectorMuestra();         
    }

    const hndlrOpcionAlmacen = (evnt: any) =>{
        formData = {...formData, [evnt.target.id]: evnt.target.value.split("-")[0].trim()};
        setFormData(formData);     
        
        emp = {...emp, almacenDefault: evnt.target.value};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));  

        LLenarSelectorMuestra();
                            
    }  
    
    const verModalDiferencia = async(obj: any, idx: number) => {

        indice = idx;
        setIndice(indice);
        row = obj;
        setRow({...row});
        setShowModalDiferencia(true);
    }

    const actualizaFila = (clasificar: any, clasificado: any)=>{
        setShowModalDiferencia(false);
        btnBuscar();        

    }
    
    const btnBuscar = async () =>{
        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestraList?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}&Almacen=${formData.Almacen}&MUESTRA_REF=${formData.Muestra}`;     
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });

        const arrDta = await JSON.parse(response.message); 
        setSHCarga(false);
        if(response.code >= 400){
            setMsgApiError(arrDta.Message);
            setShowMsgApiError(true);
        }else{     
            const auxDta:any = [];    
            arrDta.map((obj: any, index:number)=>{
                const newObj = {
                    Codigo: obj.Codigo_Rosen,
                    E: obj.Especial,
                    Descripcion: obj.Descripcion,
                    Un: obj.Unidad_Medida,
                    Costo: obj.Costo,
                    Bloqueado: obj.Bloqueado,
                    Diferencia:  obj.Diferencia,
                    Diferencia_Valorizada:obj.Diferencia_Valorizada, 
                    Saldo_SAP:obj.Saldo_SAP,          
                    Total_Stock_Bodega:obj.Total_Stock_Bodega, 
                    Total_Stock_SAP:obj.Total_Stock_SAP,       
                    Clasificado:obj.Clasificado,
                    Clasificar: obj.Clasificar,
                    Acciones: <div className=' d-flex gap-3' >
                                    <a href="#!" id={`${index}-${1}`} onClick={()=>{
                                            verModalDiferencia(obj, index);
                                        }}>
                                        <FaBars title="Ver" color={'white'}  className='bg-success rounded-circle p-1 h4'/>   
                                    </a> 
                              </div>
                };                       
                auxDta.push(newObj);  
            });               

            setRecords([...auxDta]);
            setCpRecords([...auxDta]);
        }        
    }

    const LLenarSelectorMuestra = async () => {

        if ((formData.Anno !== "") && (formData.Almacen !== "") && (formData.Centro !== "")){ 

            const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestraList?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}` + 
                                `&Almacen=${formData.Almacen}&Estado=T`;     
            const response = await httpApiGetText(recurso,{
                'Content-Type': 'application/json',
                'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
                'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
            });

            const arrDta = await JSON.parse(response.message);
            if (response.code >= 400){
                setMsgApiError(arrDta.Message);
                setShowMsgApiError(true);
            }else{
                const arrSelectMuestra: string[]= [];
                arrDta.map((obj: any)=>{
                        arrSelectMuestra.push(obj);
                });
                opcionSubMuestras = [...arrSelectMuestra];
                setOpcionSubMuestras([...opcionSubMuestras]);                
            }
        }                  
    }

    const handlerMuestras = (event: any) =>{
        if (event.target.value !== ""){
            formData.Muestra = event.target.value;    
            setFormData({...formData});  
            ValidaBtnAbrir();            
        }
    }   

    const changeTextFiltro = (evnt: any) => {
        records = [...cpRecords];
        let reg = records.filter((dato: any)=> {
            return (
                   dato.Codigo && dato.Codigo.toString().includes(evnt.target.value.toUpperCase())
                || dato.E && dato.E.includes(evnt.target.value.toUpperCase()) 
                || (dato.Descripcion && dato.Descripcion.includes(evnt.target.value.toUpperCase())) 
                || (dato.Un && dato.Un.includes(evnt.target.value.toUpperCase()))
                || (dato.Sistema && dato.Sistema.toString().includes(evnt.target.value.toUpperCase())) 
                || (dato.Control_cal_sap && dato.Control_cal_sap.toString().includes(evnt.target.value.toUpperCase())) 
                || (dato.Bloqueado && dato.Bloqueado.toString().includes(evnt.target.value.toUpperCase())) 
                || (dato.Sistema_Control_cal && dato.Sistema_Control_cal.toString().includes(evnt.target.value.toUpperCase())) 
                || (dato.Fisico && dato.Fisico.toString().includes(evnt.target.value.toUpperCase())) 
                || (dato.Diferencia && dato.Diferencia.toString().includes(evnt.target.value.toUpperCase()))
                || (dato.Clasif_Diferencia && dato.Clasif_Diferencia.includes(evnt.target.value.toUpperCase()))                
            );
        });
        setRecords([...reg]);         
    }  

    const onRowSelected = (arr: any) =>{
        rowsPreselected = arr.selectedRows;   
        setRowsPreselected([...rowsPreselected]);   
    }

    const handleClearRows = () => {
        setCLRows(!clrRows);
    } 

    const OnClasificar = async() =>{
        
        setShowCuadroDialogo(false);
        setMuestraPorc(true);   

        let sw = true;
        let idx = -1;
        while(sw){

            idx++;
            let pc = ((idx + 1)/rowsPreselected.length) * 100;
            setPerc(pc);

            const req = {
                Pais : "CL",
                Anno:formData.Anno,
                Mes:formData.Mes,
                Centro:formData.Centro,
                Almacen:formData.Almacen,
                Inv_item_id:rowsPreselected[idx].Codigo,
                Especial:(rowsPreselected[idx].E) ? rowsPreselected[idx].E: "",
                Id_clasificacion: formClasificacion                
            };
            const recurso = `${env.REACT_APP_API_URL}/api/ClasifDiferenciasInventario`;     
            const response = await httpApiPostText(recurso,"PUT", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
            }, req);
            const dta = await JSON.parse(response.message);

            if ((dta === 1) && ((idx) <= (rowsPreselected.length - 2))){
                continue;
            }else{
                break
            }
        }
        
        setMuestraPorc(false);
        handleClearRows();
        btnBuscar();
        setFormClasificacion("");
        setRowsPreselected([]);
    }

    const onAutorizarDif = async () =>{
        const req = {
            Pais: "CL",
            Anno: formData.Anno,
            Mes: formData.Mes,
            Centro: formData.Centro,
            Almacen: formData.Almacen,
            Muestra_ref: formData.Muestra,
            Estado_Clasificacion: "1",
            Usu_Autoriza: emp.user
        };
        const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestra/CambiaEstadoAutorizacion`;     
        const response = await httpApiPostText(recurso,"POST", {
        'Content-Type': 'application/json',
        'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
        'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, req);
        if (response.code >= 400){
            const msg = JSON.parse(response.message);
            setMsgApiResponse(msg.Message);
            setShowMsgApiResponse(true);
        }else{
            setMsgApiResponse("La operación se ha realizado con éxito!!!");
            setShowMsgApiResponse(true);            
        }     
    }

    const OnAutorizarClasificar = () =>{

        setShowCuadroDialogoClasificar(false);
        onAutorizarDif();

    }

    const actionNOK = (msg: any) =>{

        setMsgApiError(msg);
        setShowMsgApiError(true);
        setCLRows(!clrRows); 

    }

    const actionOK = () =>{

        setRecords([]);
        btnBuscar();
        setCLRows(!clrRows);

    }    

    const onTableDisplay = (row: any) => (row.Pend_Clasificar === 0) ;

    const exportaExcell = async () =>{

        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestraExport?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}&Almacen=${formData.Almacen}&MUESTRA_REF=${formData.Muestra}`;     
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        const arrDta = await JSON.parse(response.message); 
        exportToExcel("TablaClasMuestra", arrDta);
        setSHCarga(false);        

    }

    const perdidaPorRobo = async () =>{

        const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestra?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}&Almacen=${formData.Almacen}&muestra=${formData.Muestra}`;     
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        const arrDta = await JSON.parse(response.message); 
        if (arrDta){
            setShowSubirNube(true);
        }else{
            setMsgApiResponse("No está disponible subir archivos!!!");
            setShowMsgApiResponse(true);
        }
    }

    useEffect(()=>{

        const preFiltros = () =>{
            if ((emp.periodoDefault !== "")){
                formData = { ...formData, Anno: emp.periodoDefault.split("-")[0],  Mes: emp.periodoDefault.split("-")[1]};
                setFormData(formData);   
                                      
            }  
            if ((emp.centroDefault !== "")){
                formData = { ...formData, Centro: emp.centroDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }    
            if ((emp.almacenDefault !== "")){
                formData = { ...formData, Almacen: emp.almacenDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }       
            LLenarSelectorMuestra();
            ValidaBtnAbrir();
        }

        preFiltros();

    }, []);
     
    return(
        <Container fluid>
            <BarraMenuHz/>  
            <div >
                <div className="text-primary ms-3 mt-3">
                        Home / Clasificación / Diferencias / Muestras
                </div>
                <div className="h4 mt-4 mb-4 ms-3">CLASIFICACIÓN MUESTRAS</div>
            </div> 
            <Container fluid>   
                <Accordion  className="mb-4" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">PARÁMETROS CLASIFICACIÓN DE MUESTRAS</div></Accordion.Header>
                        <Accordion.Body>
                            <label className="h6 mb-3">Recuerda completar todos los campos con *</label> 
                            <div className="align-middle">
                                <FaCalendarAlt className="h5" /> 
                                <label className="ms-2 h4 ">Periodo</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" />

                            <div  className="d-flex flex-column col-6">
                                <label className="m-2 ">Mes/Año</label>
                                <input type="month" id="periodo" size={8}  className="ms-2"
                                    value={`${formData.Anno}-${formData.Mes}`}
                                    onChange={hndlrPeriodo} 
                                    min="1990-01" max="9999-12"
                                />
                            </div>
                            <div className="align-middle mt-4">
                                <FaTruck className="h5" /> 
                                <label className="ms-2 h4 ">Centro y Almacen</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" /> 

                            <div className="row d-flex flex-row mb-3 ">
                                <div className="mb-3 col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Centro de distribución</label>    
                                    <div><SelectCentro id="Centro" OnSeleccion={hndlrOpcionCentro}  default={emp.centroDefault} /></div>
                                </div>
                                <div className="mb-3  col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Almacen </label>    
                                    <div ><SelectAlmacenes  id="Almacen" centro={formData.Centro}  OnSeleccion={hndlrOpcionAlmacen}   default={emp.almacenDefault} /></div>
                                </div>
                                <div className="mb-3  col-md-12 col-lg-6" >
                                    <label className="form-label">Muestras </label>    
                                    <Form.Select aria-label="Default select example" id="Muestra"  value={formData.Muestra} onChange={(e) => handlerMuestras(e)}>
                                        <option key={-1} value="">Seleccione una opción</option>
                                        {
                                            opcionSubMuestras.map((obj: any, index: number)=><option key={index} value={obj.Muestra_ref}>{obj.Muestra_ref_concat}</option>)
                                        }
                                    </Form.Select>                                        
                                </div>
                                
                            </div>

                            <div className=" col-md-12 col-lg-12 text-end">
                                <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" btn border border-0" 
                                    disabled={hbltBtnAbrir} onClick={() => btnBuscar()}
                                >
                                    Buscar
                                </Button>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>  

                <Accordion className="mt-1 mb-5" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">DIFERENCIAS</div></Accordion.Header>
                        <Accordion.Body>
                            <div className="d-flex gap-4">
                                <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" btn mt-1 border-0"
                                            disabled={!(records.length > 0)}
                                            onClick={()=> {exportaExcell()}}
                                        >
                                            Exportar 
                                </Button>    
                                <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" btn mt-1 border-0"
                                            onClick={()=>{                                             
                                                if(rowsPreselected.length === 0){

                                                   setShowmsgError(true); 
                                                }else{
                                                   setShowModalMasiva(true);     
                                                }

                                            }}
                                        >
                                            Clasificación masiva 
                                </Button>  
                                <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" btn  mt-1 border-0 bg-warning"
                                            disabled={(formData.Anno === "") || (formData.Centro === "") || (formData.Almacen === "")}
                                            onClick={()=>setShowCuadroDialogoClasificar(true)}
                                        >
                                            Finalizar Clasificaciones 
                                </Button> 
                                <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" btn  mt-1 border-0 bg-danger"
                                            onClick={()=>perdidaPorRobo()}
                                            disabled={hbltBtnAbrir}
                                        >
                                            Pérdida por Robo 
                                </Button>                                                                  
                            </div>

                            <div className="row d-flex flex-row align-middle mt-2 mb-5">
                                <div className="d-flex flex-column col col-lg-6 col-md-12 mb-3 ">
                                    <label className="m-1">Filtro</label>
                                    <input type="text" id="txtBuscar" className="form-control m-1" onChange={changeTextFiltro}/>
                                </div> 
                                <DataTable
                                    columns={columns}
                                    data={records}                
                                    selectableRowsHighlight
                                    fixedHeader
                                    pagination
                                    highlightOnHover
                                    fixedHeaderScrollHeight="600px"
                                    paginationComponentOptions={pagOptions}
                                    onSelectedRowsChange={onRowSelected}
                                    clearSelectedRows={clrRows}
                                    selectableRows
                                    selectableRowDisabled={onTableDisplay}
                                />                                                       
                            </div> 
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion> 

            </Container>

            {showSubirNube && <SubirArchivoNube
                Data={cpRecords}
                Show={showSubirNube}
                Title="Subir archivos"
                BtnNokName="Cerrar"
                HanlerdClickNok={()=>{setShowSubirNube(false)}}
                HandlerPreselected={[]}     
                data={formData}           
            />}

            {showModalDiferencia && <MsgDialogDiferencia 
                Show={showModalDiferencia}                
                obj={row}
                HandlerClickOk={actualizaFila}
                form={formData}
            />}

            {showModalMasiva && <MsgDialogMasiva
                Show={showModalMasiva}                
                arrCodigos={rowsPreselected}
                muestraRef={formData.Muestra}
                actionOK={actionOK}
                actionNOK={actionNOK}
                HandlerClickOk={()=>setShowModalMasiva(false)}
                form={formData}
            />}

            {showMsgApiResponse && <MsgDialog 
                Title="Clasificación Diferencias"
                Icon="!"
                Show={showMsgApiResponse}
                BtnNokName=""
                BtnOkName="Cerrar"
                Message={MsgApiResponse}
                HandlerClickOk={()=>setShowMsgApiResponse(false)}
                HanlerdClickNok={null}
            /> }

            {showCuadroDialogo && <MsgDialog 
                Title="Clasificación Diferencias"
                Icon="!"
                Show={showCuadroDialogo}
                BtnNokName="No, cancelar"
                BtnOkName="Si, continuar"
                Message="Se van a reclasificar los ítems seleccionados, ¿Está usted seguro de continuar?"
                HandlerClickOk={OnClasificar}
                HanlerdClickNok={()=>setShowCuadroDialogo(false)}
            /> }        
            
            {showCuadroDialogoClasificar && <MsgDialog 
                Title="Clasificación Diferencia muestras"
                Icon="!"
                Show={showCuadroDialogoClasificar}
                BtnNokName="No, cancelar"
                BtnOkName="Si, continuar"
                Message="¿Está seguro de finalizar la clasificación?"
                HandlerClickOk={OnAutorizarClasificar}
                HanlerdClickNok={()=>setShowCuadroDialogoClasificar(false)}
            /> }                                  

            {showmsgError && <MsgDialog 
                Title="Clasificación Masiva"
                Icon="!"
                Show={showmsgError}
                BtnNokName=""
                BtnOkName="Cerrar"
                Message={"No ha seleccionado ítems a clasificar. Por favor, seleccionelos"}
                HandlerClickOk={()=>setShowmsgError(false)}
                HanlerdClickNok={null}
            /> }

            {/* mensaje error de api */}
            {showMsgApiError && <MsgDialog3 
                Title="Clasificación Diferencias"
                Icon="!"
                Show={showMsgApiError}
                BtnNokName=""
                BtnOkName="Cerrar"
                Message={MsgApiError}
                HandlerClickOk={()=>setShowMsgApiError(false)}
                HanlerdClickNok={null}
            />} 

            {sHCarga && <LocalMsgModalSpinner 
                Show={sHCarga}
                text="Un momento por favor, procesando..."
                color="#FF7588"
            />}  
            
            {muestraPorc && <LocalMsgModalSpinner 
                Show={muestraPorc}
                text={`Esper, procesando...(${perc.toFixed(2)}%)`}
                color="#FF7588"
            />}                       
        </Container>    
    );
}

export default ClasificacionMuestras;